<template>
  <div id="app" :class="{ darkmode: darkModeEnabled }">
    <header>
      <top-nav
        @toggle-dark-mode="darkModeEnabled = !darkModeEnabled"
        :dark-mode-enabled="darkModeEnabled"
      ></top-nav>
    </header>
    <div class="row bg-grey">
      <div class="col-12">
        <div class="container">
          <p class="version-no m-4 text-center">
             MBON Guide &amp; Database<br>
             <span class="version-no-sub bg-primary p-1 text-light">
             <strong> Version:</strong> 2.2.0</span><br>
             <span class="version-no-sub bg-primary p-1 text-light">
              <strong> Last Updated:</strong> 4/26/2021
            </span>
          </p>
        </div>
      </div>
    </div>
    <main>
      <div class="container">
        <div id="view" class="row">
          <div class="col-lg-2 border-right d-none d-lg-block">
            <sidebar></sidebar>
          </div>
          <div class="col-sm-12 col-lg-10 px-lg-5">
            <router-view> </router-view>
          </div>
        </div>
      </div>
    </main>
    <footer>
      <custom-footer></custom-footer>
    </footer>
  </div>
</template>

<script>
import Sidebar from "./components/Sidebar.vue";
import TopNav from "./components/TopNav.vue";
import CustomFooter from "./components/CustomFooter.vue";

export default {
  name: "App",
  components: {
    Sidebar,
    TopNav,
    CustomFooter,
  },
  mounted() {
    this.darkModeEnabled = JSON.parse(localStorage.darkModeStorage)
  },
  data() {
    return {
      darkModeEnabled: false,
    }; 
  },
  watch: {
    darkModeEnabled(newDarkModeState) {
      localStorage.darkModeStorage = JSON.stringify(newDarkModeState);
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#view .row {
  min-height: 100vh;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.app_wrapper {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.version-no {
  font-size: 1.5em;
}

.version-no-sub {
  font-size: 0.75em;
}

.bg-grey {
  background-color: #f5f8fa;
  border-bottom: 1px solid #ddd;
}

.row.bg-grey {
    margin: 0;
}

header {
  background: white;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  display: initial !important;
}
aside {
  flex: 1;
  border-right: 1px solid #ddd;
  min-height: 100vh;
}
main {
  flex: 3;
  padding: 1rem;
}

.content {
  display: flex;
}

/* NAVIGATION STYLING */
.navbar-nav .dropdown-item {
  font-size: 0.75em;
}

@media (min-width: 1024px) {
  .navbar-nav .dropdown-menu {
    min-width: 75vw;
    position: absolute;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
}

/* DARK MODE */
.darkmode {
  background: #0e1a2b;
  color: #ffffff !important;
}

.darkmode a {
  color: #41b883;
}

.darkmode .collapsed {
  color: #41b883 !important;
}

.darkmode .navbar-toggler {
  color: #41b883 !important;
  border-color: slategrey!important;
}

.darkmode .dropdown-menu.show {
  background: #10213d;
}

.darkmode .navbar {
  background: #152642;
  color: #ffffff !important;
}

.darkmode .navbar a {
  color: #ffffff !important;
}

.darkmode .navbar a:hover {
  background-color: #424c5c;
}

.darkmode #hamburger-toggle span {
  color: #ffffff!important
}

.darkmode .bg-grey {
  background: #304970;
  border-bottom: 1px solid slategray !important;
}

.darkmode .border-right {
  border-right: 1px solid slategray !important;
}

.darkmode .footer {
  border-top: 1px solid slategray !important;
}
</style>
