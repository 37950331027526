import { render, staticRenderFns } from "./PyonBrake.vue?vue&type=template&id=2e1e8c81&scoped=true&"
var script = {}
import style0 from "./PyonBrake.vue?vue&type=style&index=0&id=2e1e8c81&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2e1e8c81",
  null
  
)

export default component.exports