import { render, staticRenderFns } from "./DirectCancel.vue?vue&type=template&id=123676da&scoped=true&"
var script = {}
import style0 from "./DirectCancel.vue?vue&type=style&index=0&id=123676da&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "123676da",
  null
  
)

export default component.exports