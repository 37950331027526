import { render, staticRenderFns } from "./Shot-A.vue?vue&type=template&id=950df6e2&scoped=true&"
var script = {}
import style0 from "./Shot-A.vue?vue&type=style&index=0&id=950df6e2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "950df6e2",
  null
  
)

export default component.exports