<template>
  <b-navbar toggleable="lg" sticky>
    <div class="container">
      <b-navbar-brand
        ><router-link :to="{ name: 'Home' }"
          border="0"><img
            src="../assets/gundamguide-logo-small.png"
            alt="Gundam Guide Logo" border="0" /></router-link
      ></b-navbar-brand>
      <div>
      <button
        class="d-lg-none mr-3"
        :class="[darkModeEnabled ? 'light' : 'dark']"
        @click="$emit('toggle-dark-mode')"
      >
        <b-icon icon="moon" v-if="darkModeEnabled == false"></b-icon>
        <b-icon icon="sun" v-else></b-icon>
      </button>
      
      <b-navbar-toggle target="nav-collapse">
        <template #default="{ expanded }">
          <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
          <b-icon v-else icon="chevron-bar-down"></b-icon>
        </template>
      </b-navbar-toggle>
      </div>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-md-auto">
          <b-nav-item-dropdown
            text="New Players"
            class="has-megamenu nav-primary b-dropdown"
            id="NewPlayers"
          >
            <div class="row text-center text-lg-left">
              <div class="col-lg-3 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> Introduction</b-dd-group>
                  <!-- use the 'to' directive to link the b-dd-item -->
                  <b-dd-item to="/TheMostBasicOfBasics"
                    >The Most Basics of Basics</b-dd-item
                  >
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Introduction"
                  class="has-megamenu nav-primary d-lg-none ."
                >
                  <b-dd-item to="/TheMostBasicOfBasics"
                    >The Most Basics of Basics</b-dd-item
                  >
                </b-nav-item-dropdown>
              </div>
              <div class="col-lg-3 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> Controls</b-dd-group>
                  <b-dd-item to="/StickControls"
                    >Arcade Stick Controls</b-dd-item
                  >
                  <b-dd-item to="/DS4Controls"
                    >DualShock 4 Gamepad Controls</b-dd-item
                  >
                  <b-dd-item to="/Numpad">Numpad Notation</b-dd-item>
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Controls"
                  class="has-megamenu nav-primary d-lg-none"
                >
                  <b-dd-item to="/StickControls"
                    >Arcade Stick Controls</b-dd-item
                  >
                  <b-dd-item to="/DS4Controls"
                    >DualShock 4 Gamepad Controls</b-dd-item
                  >
                  <b-dd-item to="/Numpad">Numpad Notation</b-dd-item>
                </b-nav-item-dropdown>
              </div>
              <div class="col-lg-3 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group
                    ><b-icon-list /> Basic Buttons / Functions</b-dd-group
                  >
                  <b-dd-item to="/Movement">Movement</b-dd-item>
                  <b-dd-item to="/Guard">Blocking / Guard</b-dd-item>
                  <b-dd-item to="/Shot-A">Shot (A)</b-dd-item>
                  <b-dd-item to="/ChargeShot-csA"
                    ><b-icon-dot />Charge Shot</b-dd-item
                  >
                  <b-dd-item to="/Melee-B">Melee (B)</b-dd-item>
                  <b-dd-item to="/Boost-C">Boost (C)</b-dd-item>
                  <b-dd-item to="/Jump"><b-icon-dot />Jump</b-dd-item>
                  <b-dd-item to="/BoostDash"
                    ><b-icon-dot />Boost Dash (BD)</b-dd-item
                  >
                  <b-dd-item to="/Step"><b-icon-dot />Step / Dodge</b-dd-item>
                  <b-dd-item to="/Overheat"
                    ><b-icon-dot />Overheat State</b-dd-item
                  >
                  <b-dd-item to="/Switch-D">Change Target (D)</b-dd-item>
                  <b-dd-item to="/ButtonCombos">Button Combinations</b-dd-item>
                  <b-dd-item to="/SubShot"
                    ><b-icon-dot />Sub Shot / Subweapon</b-dd-item
                  >
                  <b-dd-item to="/SpecialShot"
                    ><b-icon-dot />Special Shot</b-dd-item
                  >
                  <b-dd-item to="/SpecialMelee"
                    ><b-icon-dot />Special Melee</b-dd-item
                  >
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Basic Buttons / Functions"
                  class="has-megamenu nav-primary d-lg-none"
                >
                  <b-dd-item to="/Movement">Movement</b-dd-item>
                  <b-dd-item to="/Guard">Blocking / Guard</b-dd-item>
                  <b-dd-item to="/Shot-A">Shot (A)</b-dd-item>
                  <b-dd-item to="/ChargeShot-csA"
                    ><b-icon-dot />Charge Shot</b-dd-item
                  >
                  <b-dd-item to="/Melee-B">Melee (B)</b-dd-item>
                  <b-dd-item to="/Boost-C">Boost (C)</b-dd-item>
                  <b-dd-item to="/Jump"><b-icon-dot />Jump</b-dd-item>
                  <b-dd-item to="/BoostDash"
                    ><b-icon-dot />Boost Dash (BD)</b-dd-item
                  >
                  <b-dd-item to="/Step"><b-icon-dot />Step / Dodge</b-dd-item>
                  <b-dd-item to="/Overheat"
                    ><b-icon-dot />Overheat State</b-dd-item
                  >
                  <b-dd-item to="/Switch-D">Change Target (D)</b-dd-item>
                  <b-dd-item to="/ButtonCombos">Button Combinations</b-dd-item>
                  <b-dd-item to="/SubShot"
                    ><b-icon-dot />Sub Shot / Subweapon</b-dd-item
                  >
                  <b-dd-item to="/SpecialShot"
                    ><b-icon-dot />Special Shot</b-dd-item
                  >
                  <b-dd-item to="/SpecialMelee"
                    ><b-icon-dot />Special Melee</b-dd-item
                  >
                </b-nav-item-dropdown>
              </div>
              <div class="col-lg-3">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> Burst</b-dd-group>
                  <b-dd-item to="/BurstInfo">Burst</b-dd-item>
                  <b-dd-item to="/FightingBurst"
                    ><b-icon-dot /><span class="bg-danger p-1 text-light"
                      >F i g h t i n g</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/ShootingBurst"
                    ><b-icon-dot /><span class="bg-primary p-1 text-light"
                      >S h o o t i n g</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/ExtendBurst"
                    ><b-icon-dot /><span class="bg-success p-1 text-light"
                      >E x t e n d</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/BurstAttack"
                    >Burst Attack / EX Super</b-dd-item
                  >
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Burst"
                  class="has-megamenu nav-primary d-lg-none"
                >
                  <b-dd-item to="/BurstInfo">Burst</b-dd-item>
                  <b-dd-item to="/FightingBurst"
                    ><b-icon-dot /><span class="bg-danger p-1 text-light"
                      >F i g h t i n g</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/ShootingBurst"
                    ><b-icon-dot /><span class="bg-primary p-1 text-light"
                      >S h o o t i n g</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/ExtendBurst"
                    ><b-icon-dot /><span class="bg-success p-1 text-light"
                      >E x t e n d</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/BurstAttack"
                    >Burst Attack / EX Super</b-dd-item
                  >
                </b-nav-item-dropdown>
              </div>
            </div>
            <div class="row text-center text-lg-left">
              <div class="col-lg-3 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> Cost Class</b-dd-group>
                  <b-dd-item to="/CostSystem">Cost System</b-dd-item>
                  <b-dd-item to="/Cost1500"
                    ><b-icon-dot /><span class="bg-success p-1 text-light"
                      >1500</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/Cost2000"
                    ><b-icon-dot /><span class="bg-warning p-1 text-light"
                      >2000</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/Cost2500"
                    ><b-icon-dot /><span class="bg-orange p-1 text-light"
                      >2500</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/Cost3000"
                    ><b-icon-dot /><span class="bg-danger p-1 text-light"
                      >3000</span
                    ></b-dd-item
                  >
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Cost Class"
                  class="has-megamenu nav-primary d-lg-none"
                >
                  <b-dd-item to="/CostSystem">Cost System</b-dd-item>
                  <b-dd-item to="/Cost1500"
                    ><b-icon-dot /><span class="bg-success p-1 text-light"
                      >1500</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/Cost2000"
                    ><b-icon-dot /><span class="bg-warning p-1 text-light"
                      >2000</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/Cost2500"
                    ><b-icon-dot /><span class="bg-orange p-1 text-light"
                      >2500</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/Cost3000"
                    ><b-icon-dot /><span class="bg-danger p-1 text-light"
                      >3000</span
                    ></b-dd-item
                  >
                </b-nav-item-dropdown>
              </div>
              <div class="col-lg-3 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> HUD</b-dd-group>
                  <b-dd-item to="/HUD">HUD Gauges &amp; Information</b-dd-item>
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="HUD"
                  class="has-megamenu nav-primary d-lg-none"
                >
                  <b-dd-item to="/HUD">HUD Gauges &amp; Information</b-dd-item>
                </b-nav-item-dropdown>
              </div>
              <div class="col-lg-3 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> What's Next?</b-dd-group>
                  <b-dd-item to="/WDIDN1">What Do I Do Now?</b-dd-item>
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="What's Next"
                  class="has-megamenu nav-primary d-lg-none"
                >
                  <b-dd-item to="/WDIDN1">What Do I Do Now?</b-dd-item>
                </b-nav-item-dropdown>
              </div>
            </div>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown
            text="Advanced Beginner"
            class="has-megamenu nav-success"
            id="AdvancedBeginner"
          >
            <div class="row text-center text-lg-left">
              <div class="col-lg-3 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> Introduction</b-dd-group>
                  <b-dd-item to="/TrulyBegins"
                    >Where The Game Truly Begins</b-dd-item
                  >
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Introduction"
                  class="has-megamenu nav-success d-lg-none"
                >
                  <b-dd-item to="/TrulyBegins"
                    >Where The Game Truly Begins</b-dd-item
                  >
                </b-nav-item-dropdown>
              </div>
              <div class="col-lg-3 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> EXVS Notation</b-dd-group>
                  <b-dd-item to="/Notation">Reading EXVS Notation</b-dd-item>
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="EXVS Notation"
                  class="has-megamenu nav-success d-lg-none"
                >
                  <b-dd-item to="/Notation">Reading EXVS Notation</b-dd-item>
                </b-nav-item-dropdown>
              </div>
              <div class="col-lg-3 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> Boost Canceling</b-dd-group>
                  <b-dd-item to="/BDCancel">What is Boost Canceling?</b-dd-item>
                  <b-dd-item to="/BRZunda">BR Zunda</b-dd-item>
                  <b-dd-item to="/BDMeeleCancel"
                    >Boost Dash Melee Cancel</b-dd-item
                  >
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Boost Canceling"
                  class="has-megamenu nav-success d-lg-none"
                >
                  <b-dd-item to="/BDCancel">What is Boost Canceling?</b-dd-item>
                  <b-dd-item to="/BRZunda">>BR Zunda</b-dd-item>
                  <b-dd-item to="/BDMeeleCancel"
                    >Boost Dash Melee Cancel</b-dd-item
                  >
                </b-nav-item-dropdown>
              </div>
              <div class="col-lg-3">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> Natural Canceling</b-dd-group>
                  <b-dd-item to="/DirectCancel">Direct Cancel</b-dd-item>
                  <b-dd-item to="/DirectCancel">Shot-to-Sub</b-dd-item>
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Natural Canceling"
                  class="has-megamenu nav-success d-lg-none"
                >
                  <b-dd-item to="/DirectCancel">Direct Cancel</b-dd-item>
                  <b-dd-item to="/DirectCancel">Shot-to-Sub</b-dd-item>
                </b-nav-item-dropdown>
              </div>
            </div>
            <div class="row text-center text-lg-left">
              <div class="col-lg-3 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> Melee Canceling</b-dd-group>
                  <b-dd-item to="/LeverShift">Lever Shift Inputs</b-dd-item>
                  <b-dd-item to="Stepcanceling">Stepcanceling</b-dd-item>
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Melee Canceling"
                  class="has-megamenu nav-success d-lg-none"
                >
                  <b-dd-item to="/LeverShift">Lever Shift Inputs</b-dd-item>
                  <b-dd-item to="Stepcanceling">Stepcanceling</b-dd-item>
                </b-nav-item-dropdown>
              </div>
              <div class="col-lg-3 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> Transformation</b-dd-group>
                  <b-dd-item to="/MAMode">Mobile Armor Mode (MA)</b-dd-item>
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Transformation"
                  class="has-megamenu nav-success d-lg-none"
                >
                  <b-dd-item to="/MAMode">Mobile Armor Mode (MA)</b-dd-item>
                </b-nav-item-dropdown>
              </div>
              <div class="col-lg-3 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> Advanced HUD</b-dd-group>
                  <b-dd-item to="/AddtlNot">Additional Notifications</b-dd-item>
                  <b-dd-item to="/CommUse"
                    >Comm Usage &amp; Etiquette</b-dd-item
                  >
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Advanced HUD"
                  class="has-megamenu nav-success d-lg-none"
                >
                  <b-dd-item to="/AddtlNot">Additional Notifications</b-dd-item>
                  <b-dd-item to="/CommUse"
                    >Comm Usage &amp; Etiquette</b-dd-item
                  >
                </b-nav-item-dropdown>
              </div>
              <div class="col-lg-3">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> Cutting</b-dd-group>
                  <b-dd-item to="/Cutting">What is Cutting?</b-dd-item>
                  <b-dd-item to="/DmgScaling">Damage Scaling</b-dd-item>
                  <b-dd-item to="/DownValue">Down Value</b-dd-item>
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Cutting"
                  class="has-megamenu nav-success d-lg-none"
                >
                  <b-dd-item to="/Cutting">What is Cutting?</b-dd-item>
                  <b-dd-item to="/DmgScaling">Damage Scaling</b-dd-item>
                  <b-dd-item to="/DownValue">Down Value</b-dd-item>
                </b-nav-item-dropdown>
              </div>
            </div>
            <div class="row text-center text-lg-left">
              <div class="col-lg-3 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> The Math Game</b-dd-group>
                  <b-dd-item to="/CostOver">Cost Over</b-dd-item>
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="The Math Game"
                  class="has-megamenu nav-success d-lg-none"
                >
                  <b-dd-item to="/CostOver">Cost Over</b-dd-item>
                </b-nav-item-dropdown>
              </div>
              <div class="col-lg-3 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> What's Next?</b-dd-group>
                  <b-dd-item to="/WDIDN2">What do I do now?</b-dd-item>
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="What's Next?"
                  class="has-megamenu nav-success d-lg-none"
                >
                  <b-dd-item to="/WDIDN2">What do I do now?</b-dd-item>
                </b-nav-item-dropdown>
              </div>
            </div>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown
            text="Intermediate"
            class="has-megamenu nav-orange"
            id="Intermediate"
          >
            <div class="row text-center text-lg-left">
              <div class="col-lg-3 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> Introduction</b-dd-group>
                  <b-dd-item to="/EveryLittleBit"
                    >Every Little Bit Counts</b-dd-item
                  >
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Introduction"
                  class="has-megamenu nav-orange d-lg-none"
                >
                  <b-dd-item to="/EveryLittleBit"
                    >Every Little Bit Counts</b-dd-item
                  >
                </b-nav-item-dropdown>
              </div>
              <div class="col-lg-3 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> Boost Management</b-dd-group>
                  <b-dd-item to="/BarColor"
                    ><b-icon-dot />Bar Color &amp; Why It Matters</b-dd-item
                  >
                  <b-dd-item to="/BDHop">Boost Dash Jump / Boost Hop</b-dd-item>
                  <b-dd-item to="/Fuwafuwa"
                    ><b-icon-dot />Boost Hop Landing (fuwafuwa)</b-dd-item
                  >
                  <b-dd-item to="/Fuwastep">Fuwastep</b-dd-item>
                  <b-dd-item to="/JumpCancel"
                    ><b-icon-dot />Jump Cancel</b-dd-item
                  >
                  <b-dd-item to="/VernierState">Vernier State</b-dd-item>
                  <b-dd-item to="/OverheatPenalty">Overheat Penalty</b-dd-item>
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Boost Management"
                  class="has-megamenu nav-orange d-lg-none"
                >
                  <b-dd-item to="/BarColor"
                    ><b-icon-dot />Bar Color &amp; Why It Matters</b-dd-item
                  >
                  <b-dd-item to="/BDHop">Boost Dash Jump / Boost Hop</b-dd-item>
                  <b-dd-item to="/Fuwafuwa"
                    ><b-icon-dot />Boost Hop Landing (fuwafuwa)</b-dd-item
                  >
                  <b-dd-item to="/Fuwastep">Fuwastep</b-dd-item>
                  <b-dd-item to="/JumpCancel"
                    ><b-icon-dot />Jump Cancel</b-dd-item
                  >
                  <b-dd-item to="/VernierState">Vernier State</b-dd-item>
                  <b-dd-item to="/OverheatPenalty">Overheat Penalty</b-dd-item>
                </b-nav-item-dropdown>
              </div>
              <div class="col-lg-3 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> Adv. Shoot &amp; More</b-dd-group>
                  <b-dd-item to="/FiveShoot">5 Shoot-to-Hit Moments</b-dd-item>
                  <b-dd-item to="/ProjTrack">Projectile Tracking</b-dd-item>
                  <b-dd-item to="/RetColor"
                    ><b-icon-dot />Reticle Color</b-dd-item
                  >
                  <b-dd-item to="/MuzzCorr">Muzzle Correction</b-dd-item>
                  <b-dd-item to="/WeapSkew"
                    ><b-icon-dot />Weapon Skew</b-dd-item
                  >
                  <b-dd-item to="/FireArc"><b-icon-dot />Firing Arc</b-dd-item>
                  <b-dd-item to="/CamChange">Camera Change</b-dd-item>
                  <b-dd-item to="/TrackCancel"
                    >Tracking &amp; Cancel Routes</b-dd-item
                  >
                  <b-dd-item to="/RedGreen"
                    ><b-icon-dot />Red-to-Green Tracking</b-dd-item
                  >
                  <b-dd-item to="/SecondImpact">Second Impact</b-dd-item>
                  <b-dd-item to="/Multilock">Multi-lock</b-dd-item>
                  <b-dd-item to="/SwivelSwerve">Swivel / Swerve</b-dd-item>
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Adv. Shoot &amp; More"
                  class="has-megamenu nav-orange d-lg-none"
                >
                  <b-dd-item to="/FiveShoot">5 Shoot-to-Hit Moments</b-dd-item>
                  <b-dd-item to="/ProjTrack">Projectile Tracking</b-dd-item>
                  <b-dd-item to="/RetColor"
                    ><b-icon-dot />Reticle Color</b-dd-item
                  >
                  <b-dd-item to="/MuzzCorr">Muzzle Correction</b-dd-item>
                  <b-dd-item to="/WeapSkew"
                    ><b-icon-dot />Weapon Skew</b-dd-item
                  >
                  <b-dd-item to="/FireArc"><b-icon-dot />Firing Arc</b-dd-item>
                  <b-dd-item to="/CamChange">Camera Change</b-dd-item>
                  <b-dd-item to="/TrackCancel"
                    >Tracking &amp; Cancel Routes</b-dd-item
                  >
                  <b-dd-item to="/RedGreen"
                    ><b-icon-dot />Red-to-Green Tracking</b-dd-item
                  >
                  <b-dd-item to="/SecondImpact">Second Impact</b-dd-item>
                  <b-dd-item to="/Multilock">Multi-lock</b-dd-item>
                  <b-dd-item to="/SwivelSwerve">Swivel / Swerve</b-dd-item>
                </b-nav-item-dropdown>
              </div>
              <div class="col-lg-3 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> Weapons &amp; Tools</b-dd-group>
                  <b-dd-item to="/WeaponTypes"
                    >Complete List &amp; Usages</b-dd-item
                  >
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Weapons &amp; Tool"
                  class="has-megamenu nav-orange d-lg-none"
                >
                  <b-dd-item to="/WeaponTypes"
                    >Complete List &amp; Usages</b-dd-item
                  >
                </b-nav-item-dropdown>
              </div>
            </div>
            <div class="row text-center text-lg-left">
              <div class="col-lg-3 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> Team Composition</b-dd-group>
                  <b-dd-item to="/TeamComps"
                    >What is Team Composition?</b-dd-item
                  >
                  <b-dd-item to="/Comp1515"
                    ><b-icon-dot /><span class="bg-success p-1 text-light"
                      >1500</span
                    >
                    &amp;
                    <span class="bg-success p-1 text-light"
                      >1500</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/Comp1520"
                    ><b-icon-dot /><span class="bg-success p-1 text-light"
                      >1500</span
                    >
                    &amp;
                    <span class="bg-warning p-1 text-light"
                      >2000</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/Comp1525"
                    ><b-icon-dot /><span class="bg-success p-1 text-light"
                      >1500</span
                    >
                    &amp;
                    <span class="bg-orange p-1 text-light"
                      >2500</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/Comp1530"
                    ><b-icon-dot /><span class="bg-success p-1 text-light"
                      >1500</span
                    >
                    &amp;
                    <span class="bg-danger p-1 text-light"
                      >3000</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/Comp2020"
                    ><b-icon-dot /><span class="bg-warning p-1 text-light"
                      >2000</span
                    >
                    &amp;
                    <span class="bg-warning p-1 text-light"
                      >2000</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/Comp2025"
                    ><b-icon-dot /><span class="bg-warning p-1 text-light"
                      >2000</span
                    >
                    &amp;
                    <span class="bg-orange p-1 text-light"
                      >2500</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/Comp2030"
                    ><b-icon-dot /><span class="bg-warning p-1 text-light"
                      >2000</span
                    >
                    &amp;
                    <span class="bg-danger p-1 text-light"
                      >3000</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/Comp2525"
                    ><b-icon-dot /><span class="bg-orange p-1 text-light"
                      >2500</span
                    >
                    &amp;
                    <span class="bg-orange p-1 text-light"
                      >2500</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/Comp2530"
                    ><b-icon-dot /><span class="bg-orange p-1 text-light"
                      >2500</span
                    >
                    &amp;
                    <span class="bg-danger p-1 text-light"
                      >3000</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/Comp3030"
                    ><b-icon-dot /><span class="bg-danger p-1 text-light"
                      >3000</span
                    >
                    &amp;
                    <span class="bg-danger p-1 text-light"
                      >3000</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/FrontBack">Front Role / Back Role</b-dd-item>
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Team Composition"
                  class="has-megamenu nav-orange d-lg-none"
                >
                  <b-dd-item to="/TeamComps"
                    >What is Team Composition?</b-dd-item
                  >
                  <b-dd-item to="/Comp1515"
                    ><b-icon-dot /><span class="bg-success p-1 text-light"
                      >1500</span
                    >
                    &amp;
                    <span class="bg-success p-1 text-light"
                      >1500</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/Comp1520"
                    ><b-icon-dot /><span class="bg-success p-1 text-light"
                      >1500</span
                    >
                    &amp;
                    <span class="bg-warning p-1 text-light"
                      >2000</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/Comp1525"
                    ><b-icon-dot /><span class="bg-success p-1 text-light"
                      >1500</span
                    >
                    &amp;
                    <span class="bg-orange p-1 text-light"
                      >2500</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/Comp1530"
                    ><b-icon-dot /><span class="bg-success p-1 text-light"
                      >1500</span
                    >
                    &amp;
                    <span class="bg-danger p-1 text-light"
                      >3000</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/Comp2020"
                    ><b-icon-dot /><span class="bg-warning p-1 text-light"
                      >2000</span
                    >
                    &amp;
                    <span class="bg-warning p-1 text-light"
                      >2000</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/Comp2025"
                    ><b-icon-dot /><span class="bg-warning p-1 text-light"
                      >2000</span
                    >
                    &amp;
                    <span class="bg-orange p-1 text-light"
                      >2500</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/Comp2030"
                    ><b-icon-dot /><span class="bg-warning p-1 text-light"
                      >2000</span
                    >
                    &amp;
                    <span class="bg-danger p-1 text-light"
                      >3000</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/Comp2525"
                    ><b-icon-dot /><span class="bg-orange p-1 text-light"
                      >2500</span
                    >
                    &amp;
                    <span class="bg-orange p-1 text-light"
                      >2500</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/Comp2530"
                    ><b-icon-dot /><span class="bg-orange p-1 text-light"
                      >2500</span
                    >
                    &amp;
                    <span class="bg-danger p-1 text-light"
                      >3000</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/Comp3030"
                    ><b-icon-dot /><span class="bg-danger p-1 text-light"
                      >3000</span
                    >
                    &amp;
                    <span class="bg-danger p-1 text-light"
                      >3000</span
                    ></b-dd-item
                  >
                  <b-dd-item to="/FrontBack">Front Role / Back Role</b-dd-item>
                </b-nav-item-dropdown>
              </div>
              <div class="col-lg-3">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> What's Next?</b-dd-group>
                  <b-dd-item to="/WDIDN3">What Do I Do Now?</b-dd-item>
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="What's Next?"
                  class="has-megamenu nav-orange d-lg-none"
                >
                  <b-dd-item to="/WDIDN3">What Do I Do Now?</b-dd-item>
                </b-nav-item-dropdown>
              </div>
            </div>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown
            text="Advanced Intermediate"
            class="has-megamenu nav-danger"
            id="AdvancedIntermediate"
          >
            <div class="row text-center text-lg-left">
              <div class="col-lg-3 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> Introduction</b-dd-group>
                  <b-dd-item to="/FineTune"
                    >Fine Tuning for Max Effect</b-dd-item
                  >
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Introduction"
                  class="has-megamenu nav-danger d-lg-none"
                >
                  <b-dd-item to="/FineTune"
                    >Fine Tuning for Max Effect</b-dd-item
                  >
                </b-nav-item-dropdown>
              </div>
              <div class="col-lg-3 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group
                    ><b-icon-list /> Adv. Movement &amp; Tools</b-dd-group
                  >
                  <b-dd-item to="/DirectInf">Directional Influence</b-dd-item>
                  <b-dd-item to="/MeleeSwitch">Target Switch + Melee</b-dd-item>
                  <b-dd-item to="/FastfallOp"
                    >Fast Fall &amp; Options</b-dd-item
                  >
                  <b-dd-item to="/AssistMain"
                    ><b-icon-dot />Assist~Main Cancel</b-dd-item
                  >
                  <b-dd-item to="/MeleeCS"
                    ><b-icon-dot />Melee~CS Cancel (Karakyan)</b-dd-item
                  >
                  <b-dd-item to="/BackturnShot"
                    ><b-icon-dot />Backturn Shot Cancel</b-dd-item
                  >
                  <b-dd-item to="/AAFastfall"
                    ><b-icon-dot />Active Assist Fast Fall</b-dd-item
                  >
                  <b-dd-item to="/ReloadFastfall"
                    ><b-icon-dot />Reload Fast Fall Cancel (Relokyan)</b-dd-item
                  >
                  <b-dd-item to="/SBurstShen"
                    ><b-icon-dot /><span class="bg-primary p-1 text-light"
                      >S Burst</span
                    >
                    Shenanigans</b-dd-item
                  >
                  <b-dd-item to="/YellowGrab"
                    ><b-icon-dot />Yellow Lock KD Grabs</b-dd-item
                  >
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Adv. Movement &amp; Tools"
                  class="has-megamenu nav-danger d-lg-none"
                >
                  <b-dd-item to="/DirectInf">Directional Influence</b-dd-item>
                  <b-dd-item to="/MeleeSwitch">Target Switch + Melee</b-dd-item>
                  <b-dd-item to="/FastfallOp"
                    >Fast Fall &amp; Options</b-dd-item
                  >
                  <b-dd-item to="/AssistMain"
                    ><b-icon-dot />Assist~Main Cancel</b-dd-item
                  >
                  <b-dd-item to="/MeleeCS"
                    ><b-icon-dot />Melee~CS Cancel (Karakyan)</b-dd-item
                  >
                  <b-dd-item to="/BackturnShot"
                    ><b-icon-dot />Backturn Shot Cancel</b-dd-item
                  >
                  <b-dd-item to="/AAFastfall"
                    ><b-icon-dot />Active Assist Fast Fall</b-dd-item
                  >
                  <b-dd-item to="/ReloadFastfall"
                    ><b-icon-dot />Reload Fast Fall Cancel (Relokyan)</b-dd-item
                  >
                  <b-dd-item to="/SBurstShen"
                    ><b-icon-dot /><span class="bg-primary p-1 text-light"
                      >S Burst</span
                    >
                    Shenanigans</b-dd-item
                  >
                  <b-dd-item to="/YellowGrab"
                    ><b-icon-dot />Yellow Lock KD Grabs</b-dd-item
                  >
                </b-nav-item-dropdown>
              </div>
              <div class="col-lg-3 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group
                    ><b-icon-list /> Adv. Movement &amp; Tools</b-dd-group
                  >
                  <b-dd-item to="/BrakeCancel">Brake Cancelling</b-dd-item>
                  <b-dd-item to="/Sliding">Sliding</b-dd-item>
                  <b-dd-item to="/Pyonkaku">Helmbreakers (Pyonkaku)</b-dd-item>
                  <b-dd-item to="/PyonBrake"
                    ><b-icon-dot />Pyon Brake Cancels</b-dd-item
                  >
                  <b-dd-item to="/RetreatPenalty"
                    >Retreat Boost Penalty</b-dd-item
                  >
                  <b-dd-item to="/GreenLockMelee"
                    >Melee in Green Lock Range</b-dd-item
                  >
                  <b-dd-item to="/OverheatStall"
                    >Overheat Stalling (Agaki)</b-dd-item
                  >
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Adv. Movement &amp; Tools"
                  class="has-megamenu nav-danger d-lg-none"
                >
                  <b-dd-item to="/BrakeCancel">Brake Cancelling</b-dd-item>
                  <b-dd-item to="/Sliding">Sliding</b-dd-item>
                  <b-dd-item to="/Pyonkaku">Helmbreakers (Pyonkaku)</b-dd-item>
                  <b-dd-item to="/PyonBrake"
                    ><b-icon-dot />Pyon Brake Cancels</b-dd-item
                  >
                  <b-dd-item to="/RetreatPenalty"
                    >Retreat Boost Penalty</b-dd-item
                  >
                  <b-dd-item to="/GreenLockMelee"
                    >Melee in Green Lock Range</b-dd-item
                  >
                  <b-dd-item to="/OverheatStall"
                    >Overheat Stalling (Agaki)</b-dd-item
                  >
                </b-nav-item-dropdown>
              </div>
              <div class="col-lg-3">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> What's Next?</b-dd-group>
                  <b-dd-item to="/WDIDN4">What Do I Do Now?</b-dd-item>
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="What's Next?"
                  class="has-megamenu nav-danger d-lg-none"
                >
                  <b-dd-item to="/WDIDN4">What Do I Do Now?</b-dd-item>
                </b-nav-item-dropdown>
              </div>
            </div>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown
            text="Expert"
            class="has-megamenu nav-grey"
            id="Expert"
          >
            <div class="row text-center text-lg-left">
              <div class="col-lg-3 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group
                    ><b-icon-list /> Active Contribution &amp;
                    Teaching</b-dd-group
                  >
                  <b-dd-item to="/YouShouldTeach">You Should Teach!</b-dd-item>
                  <b-dd-item to="/WhyShouldTeach"
                    >Why Should You Teach?</b-dd-item
                  >
                  <b-dd-item to="/HowToTeach">How to Teach</b-dd-item>
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Active Contribution &amp; Teaching"
                  class="has-megamenu nav-grey d-lg-none"
                >
                  <b-dd-item to="/YouShouldTeach">You Should Teach!</b-dd-item>
                  <b-dd-item to="/WhyShouldTeach"
                    >Why Should You Teach?</b-dd-item
                  >
                  <b-dd-item to="/HowToTeach">How to Teach</b-dd-item>
                </b-nav-item-dropdown>
              </div>
              <div class="col-lg-3 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group
                    ><b-icon-list /> Help Craft &amp; Evolve EXVS Game
                    Theory</b-dd-group
                  >
                  <b-dd-item to="/InfoSynth">Information Synthesis</b-dd-item>
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="This text will be much too long to fit"
                  class="has-megamenu nav-grey d-lg-none"
                >
                  <b-dd-item to="/InfoSynth">Information Synthesis</b-dd-item>
                </b-nav-item-dropdown>
              </div>
              <div class="col-lg-3 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group
                    ><b-icon-list /> Playing with Intentionality</b-dd-group
                  >
                  <b-dd-item to="/LookAhead">Looking Ahead</b-dd-item>
                  <b-dd-item to="/ReadRoom">Reading the Room</b-dd-item>
                  <b-dd-item to="/AutoResp">Automatic Response</b-dd-item>
                  <b-dd-item to="/AdapResp">Adapative Response</b-dd-item>
                  <b-dd-item to="/NoWaste">No Wasted Actions</b-dd-item>
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Playing with Intentionality"
                  class="has-megamenu nav-grey d-lg-none"
                >
                  <b-dd-item to="/LookAhead">Looking Ahead</b-dd-item>
                  <b-dd-item to="/ReadRoom">Reading the Room</b-dd-item>
                  <b-dd-item to="/AutoResp">Automatic Response</b-dd-item>
                  <b-dd-item to="/AdapResp">Adapative Response</b-dd-item>
                  <b-dd-item to="/NoWaste">No Wasted Actions</b-dd-item>
                </b-nav-item-dropdown>
              </div>
              <div class="col-lg-3">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> There Is...</b-dd-group>
                  <b-dd-item to="/NoGuide">No Guide For This Part</b-dd-item>
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="There Is..."
                  class="has-megamenu nav-grey d-lg-none"
                >
                  <b-dd-item to="/NoGuide">No Guide For This Part</b-dd-item>
                </b-nav-item-dropdown>
              </div>
            </div>
            <div class="row text-center text-lg-left">
              <div class="col-lg-3">
                <div class="d-none d-lg-block">
                  <b-dd-group
                    ><b-icon-list /> Community Participation</b-dd-group
                  >
                  <b-dd-item to="/SurePath">...is the Surest Path</b-dd-item>
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Community Participation"
                  class="has-megamenu nav-grey d-lg-none"
                >
                  <b-dd-item to="/SuraPath">...is the Surest Path</b-dd-item>
                </b-nav-item-dropdown>
              </div>
            </div>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown
            text="Suit Database"
            class="has-megamenu nav-grey"
            id="SuitDatabase"
          >
            <div class="row text-center text-lg-left">
              <div class="col-12 border-right">
                <div class="d-none d-lg-block">
                  <b-dd-group><b-icon-list /> Suits</b-dd-group>
                  <b-dd-item
                    ><span class="bg-success p-1 text-light">1500</span> (Coming
                    Soon)
                  </b-dd-item>
                  <b-dd-item
                    ><span class="bg-warning p-1 text-light">2000</span> (Coming
                    Soon)
                  </b-dd-item>
                  <b-dd-item
                    ><span class="bg-orange p-1 text-light">2500</span> (Coming
                    Soon)
                  </b-dd-item>
                  <b-dd-item
                    ><span class="bg-danger p-1 text-light">3000</span> (Coming
                    Soon)
                  </b-dd-item>
                  <b-dd-item>Competitive Tier List (Coming Soon)</b-dd-item>
                </div>
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Suits"
                  class="has-megamenu nav-grey d-lg-none"
                >
                  <b-dd-item
                    ><span class="bg-success p-1 text-light">1500</span> (Coming
                    Soon)
                  </b-dd-item>
                  <b-dd-item
                    ><span class="bg-warning p-1 text-light">2000</span> (Coming
                    Soon)
                  </b-dd-item>
                  <b-dd-item
                    ><span class="bg-orange p-1 text-light">2500</span> (Coming
                    Soon)
                  </b-dd-item>
                  <b-dd-item
                    ><span class="bg-danger p-1 text-light">3000</span> (Coming
                    Soon)
                  </b-dd-item>
                  <b-dd-item>Competitive Tier List (Coming Soon)</b-dd-item>
                </b-nav-item-dropdown>
              </div>
            </div>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown
            text=" Resources &amp; Other Links"
            class="has-megamenu nav-grey d-lg-none"
          >
            <div class="row text-center text-lg-left">
              <div class="col-lg-3 border-right">
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Introduction"
                  class="has-megamenu nav-grey d-lg-none"
                >
                  <b-dd-item to="/dedication">Dedication</b-dd-item>
                  <b-dd-item to="/foreword">Foreword</b-dd-item>
                  <b-dd-item to="/credits">Credits</b-dd-item>
                  <!-- <b-dd-item>How To Use This Guide</b-dd-item> -->
                </b-nav-item-dropdown>
              </div>
              <div class="col-lg-3 border-right">
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Discord Servers"
                  class="has-megamenu nav-grey d-lg-none"
                >
                  <b-dd-item href="https://discord.gg/tjaGuA3" target="_blank"
                    >Extreme Evolution</b-dd-item
                  >
                  <b-dd-item href="https://discord.gg/QbPwGjK" target="_blank"
                    >BRS #GundamPilots</b-dd-item
                  >
                  <b-dd-item href="https://discord.gg/vtdV6kR" target="_blank"
                    >Newtype Academy</b-dd-item
                  >
                  <b-dd-item href="https://discord.gg/xTGm5y" target="_blank"
                    >GN Resonance</b-dd-item
                  >
                  <b-dd-item
                    href="https://discord.gg/0XZ1jyybIVCeK2dS"
                    target="_blank"
                    >Gundam EXVS Australia</b-dd-item
                  >
                  <b-dd-item href="https://discord.gg/yjTV9yY" target="_blank"
                    >EU Gundam EXVS</b-dd-item
                  >
                  <b-dd-item href="https://discord.gg/BcCBK6E" target="_blank"
                    >NGI Events</b-dd-item
                  >
                  <b-dd-item href="https://discord.gg/K6mJeyU" target="_blank"
                    >GundamEXVS_SEA</b-dd-item
                  >
                  <b-dd-item href="https://discord.gg/ZhRs4mg" target="_blank"
                    >r/Gundam &amp; r/Gunpla</b-dd-item
                  >
                  <b-dd-item href="https://discord.gg/ubw" target="_blank"
                    >UnlimtedBrettWorks</b-dd-item
                  >
                  <b-dd-item href="https://discord.gg/TPNNNT" target="_blank"
                    >Gundam HQ</b-dd-item
                  >
                </b-nav-item-dropdown>
              </div>
              <div class="col-lg-3 border-right">
                <b-nav-item-dropdown
                  id="nestedDropDown"
                  text="Other Resources"
                  class="has-megamenu nav-grey d-lg-none"
                >
                  <b-dd-item
                    href="https://w.atwiki.jp/gundamexvsmbon/"
                    target="_blank"
                    >JP MBON Wiki</b-dd-item
                  >
                  <b-dd-item
                    href="https://translate.google.com/translate?sl=ja&tl=en&u=https%3A%2F%2Fw.atwiki.jp%2Fgundamexvsmbon%2F"
                    target="_blank"
                    >JP MBON Wiki (EN Translation)</b-dd-item
                  >
                  <b-dd-item
                    href="https://ggez.space/mbon-wiki/"
                    target="_blank"
                    >GGEZ MBON Wiki</b-dd-item
                  >
                  <b-dd-item
                    href="https://ggez.space/3000-cost-mobile-suits/"
                    target="_blank"
                    >GGEZ -
                    <span class="bg-danger p-1 text-light"
                      >3000 Cost</span
                    ></b-dd-item
                  >
                  <b-dd-item
                    href="https://ggez.space/2500-cost-mobile-suits/"
                    target="_blank"
                    >GGEZ -
                    <span class="bg-orange p-1 text-light"
                      >2500 Cost</span
                    ></b-dd-item
                  >
                  <b-dd-item
                    href="https://ggez.space/2000-cost-mobile-suits/"
                    target="_blank"
                    >GGEZ -
                    <span class="bg-warning p-1 text-light"
                      >2000 Cost</span
                    ></b-dd-item
                  >
                  <b-dd-item
                    href="https://ggez.space/1500-cost-mobile-suits/"
                    target="_blank"
                    >GGEZ -
                    <span class="bg-success p-1 text-light"
                      >1500 Cost</span
                    ></b-dd-item
                  >
                  <b-dd-item
                    href="https://reddit.com/r/GundamEXVS"
                    target="_blank"
                    >r/GundamEXVS</b-dd-item
                  >
                  <b-dd-item
                    href="https://sites.google.com/view/mbon-guide"
                    target="_blank"
                    >UBW MBON Guide</b-dd-item
                  >
                  <b-dd-item
                    href="https://docs.google.com/document/d/1YStVb9xGbXOY3QRYd2TDppzMzNFucXYK_NDNyT7yg9c/edit?usp=sharing"
                    target="_blank"
                    >EXEV / BRS MBON Guide</b-dd-item
                  >
                </b-nav-item-dropdown>
              </div>
            </div>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown
            text=" Support This Project!"
            class="has-megamenu nav-grey d-lg-none"
          >
            <div class="row text-center text-lg-left">
              <div class="col-lg-3 border-right">
                <b-dd-item
                  href="https://www.patreon.com/GundamGuide"
                  target="_blank"
                  class="has-megamenu nav-grey d-lg-none"
                  style="color: rgba(255, 86, 0, 1)"
                  >GundamGuide.com Patreon</b-dd-item
                >
              </div>
            </div>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </div>
    <div class="d-none d-lg-block">
      <button
        :class="[darkModeEnabled ? 'light' : 'dark']"
        @click="$emit('toggle-dark-mode')"
      >
        <b-icon icon="moon" v-if="darkModeEnabled == false"></b-icon>
        <b-icon icon="sun" v-else></b-icon>
      </button>
    </div>
  </b-navbar>
</template>

<script>
export default {
  name: "TopNav",
  props: ["darkModeEnabled"],
  data() {
    return {
      isNestedDropDownVisibile: false,
    };
  },
  mounted: function () {
    this.$root.$on("bv::dropdown::show", (bvEvent) => {
      if (bvEvent.componentId === "nestedDropDown") {
        this.isNestedDropDownVisibile = true;
      }
    });
    this.$root.$on("bv::dropdown::hide", (bvEvent) => {
      if (bvEvent.componentId === "nestedDropDown") {
        this.isNestedDropDownVisibile = false;
      }
      if (this.isNestedDropDownVisibile) {
        bvEvent.preventDefault();
      }
    });
  },
};
</script>

<style scoped>
nav {
  background-color: white;
}

.dark {
  background: none;
  color: black;
  border: none;
}

.light {
  background: none;
  color: white;
  border: none;
}

.nav-item /deep/ .dropdown-item {
  white-space: unset !important;
  border-bottom: 1px solid rgba(221, 221, 221, 0.301);
}
.row {
  margin: 0;
}

.bg-orange {
  background-color: #ff8300;
}

.nav-primary /deep/ li {
  color: #007bff !important;
  font-weight: bold;
}

.nav-success /deep/ li {
  color: #28a745 !important;
  font-weight: bold;
}

.nav-orange /deep/ li {
  color: #ff8300 !important;
  font-weight: bold;
}

.nav-danger /deep/ li {
  color: #dc3545 !important;
  font-weight: bold;
}

.nav-grey /deep/ li {
  color: #86939e !important;
  font-weight: bold;
}

#nestedDropDown /deep/ li {
  text-align: center;
}

#nestedDropDown.nav-primary /deep/ span {
  color: #007bff !important;
  font-weight: bold;
}

#nestedDropDown.nav-primary /deep/ span.text-light {
  color: white !important;
}

#nestedDropDown.nav-success /deep/ span {
  color: #28a745 !important;
  font-weight: bold;
}

#nestedDropDown.nav-success /deep/ span.text-light {
  color: white !important;
}

#nestedDropDown.nav-orange /deep/ span {
  color: #ff8300 !important;
  font-weight: bold;
}

#nestedDropDown.nav-orange /deep/ span.text-light {
  color: white !important;
}

#nestedDropDown.nav-danger /deep/ span {
  color: #dc3545 !important;
  font-weight: bold;
}

#nestedDropDown.nav-danger /deep/ span.text-light {
  color: white !important;
}

#nestedDropDown.nav-grey /deep/ span {
  color: #86939e !important;
  font-weight: bold;
}

#nestedDropDown.nav-grey /deep/ span.text-light {
  color: white !important;
}

@media (min-width: 992px) {
  .has-megamenu {
    position: static !important;
  }
}
</style>