<template>
  <div class="home">
    <p>
        || TRANSMISSION BEGINS ||
        </p>
        <section>
        <p>    
        <h1>Welcome to GundamGuide.com!</h1>
        <img src='../assets/gundamguide-logo-front.png' class="img-fluid" alt="Gundam Guide Logo"><br><br>
    </section>
    <p>
        This site serves as the nexus point for <strong>Mobile Suit Gundam Extreme Versus Maxiboost ON</strong> in the West. If you're looking to learn how to play, you'll find the most detailed English-language guide to the game in the upper menu bar. If you're looking for further information, Discord servers, EXVS content creators, or other online resources for the game, check the left-hand sidebar.<br><br>
    </p>
    
    <hr><br>
        <section>
            <h2><strong>How To Use The Guide</strong></h2>
            <div class="content-container">
            The guide contained at <strong>the top menu bar</strong> is designed to include five main sections, starting with the brand-new, first-time player concerned with learning the basics, and ending at the well-versed expert player concerned with advanced mechanical application, mid-match game theory, and community building. These sections have been layered and scaffolded so that each section of skills and concepts should logically lead into the techniques and applications taught in later sections.<br><br>

           <strong>Under no circumstances should you try and digest the entire guide at once.</strong>  This will lead to a lesser degree of practical understanding (which is gained through repeated, purposeful application), and therefore, it will risk diminishing the effectiveness and usefullness of what this guide is attempting to teach you.<br><br>

           Instead, select a competency level that you feel best describes where you are with the game. Find a technique or two in that section to begin practicing, and try to implement those techniques into your gameplay. Then, when you feel that you have them sufficiently integrated into your natural reactions, find another technique, concept or skill from that same section. When you've exhausted that section's information, and you've implemented all that section has to offer you, feel free to move onto the next section.<br><br>

           <hr><br>
            
            When asking yourself "where do I begin," please consider the following as a rough guideline:<br><br><br>
    
            <h3><strong><span class="text-primary">New Players</span></strong></h3>
            <p>
            This section is geared towards the absolute beginner. <br>
             <router-link :to="{ name: 'TheMostBasicOfBasics' }">If this is the very first time you've ever picked up the game, you should begin your piloting journey here.</router-link><br><br>
            </p>
            <h3><strong><span class="text-success">Advanced Beginner</span></strong></h3>
            <p>
            This section is for players that have already learned the core basics, and are looking to begin understanding how the game is truly played. Expect to start combining what you've learned.<br><router-link :to="{ name: 'TrulyBegins' }">Completing this section will prepare you for online play.</router-link><br><br>
            </p>
            <h3><strong><span class="text-warning">Intermediate</span></strong></h3>
            <p>
            This section caters to players that are looking to improve their match performance beyond what simple application of the core basics will allow. <br><router-link :to="{ name: 'EveryLittleBit' }">If you're looking to "get good," this is where you start.</router-link><br><br>
            </p>
            <h3><strong><span class="text-danger">Advanced Intermediate</span></strong></h3>
            <p>
            This section aims to sharpen the skills of players that already have a firm grasp on fundamental concepts, as well as how the game is played with regard to team strategy and match flow.<br><router-link :to="{ name: 'FineTune' }">This is where you learn how to get <em>better</em> than good.</router-link><br><br>
            </p>
            <h3><strong><span>Expert</span></strong></h3>
            <p>
            This section is focused on the more esoteric concepts of experience in this game, and how to help grow the scene and community by passing on that knowledge to those seeking to find greater understanding. The concepts here are applicable to everyone, but they're meant for players that have extensive knowledge and experience to share.<br><router-link :to="{ name: 'YouShouldTeach' }">This is a section for teachers, not students.</router-link><br><br>
            </p>
          
        </div>
          </section>
          <hr><br><br>
<p>
        If you have any questions, feel free to ask over at <a href="https://discord.gg/tjaGuA3">Extreme Evolution Discord</a> or <a href="https://discord.gg/vtdV6kR">Newtype Academy</a>. You'll find the authors of this guide there, guaranteed.<br><br>
    </p>
          <p>
        <em><strong>Good hunting, pilots.</strong></em>
        </p>
        <p>
        ||TRANSMISSION ENDS||
    </p>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
    
  }
}
</script>
