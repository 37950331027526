import { render, staticRenderFns } from "./Step.vue?vue&type=template&id=05fbd7db&scoped=true&"
var script = {}
import style0 from "./Step.vue?vue&type=style&index=0&id=05fbd7db&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05fbd7db",
  null
  
)

export default component.exports