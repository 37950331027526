import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Dedication from '../views/Dedication.vue'
import Foreword from '../views/Foreword.vue'
import Credits from '../views/Credits.vue'
import TheMostBasicOfBasics from '../views/TheMostBasicOfBasics.vue'
import DS4Controls from '../views/DS4Controls.vue'
import StickControls from '../views/StickControls.vue'
import Numpad from '../views/numpad.vue'
import Movement from '../views/Movement.vue'
import Guard from '../views/Guard.vue'
import Shot_A from '../views/Shot-A.vue'
import ChargeShot_csA from '../views/ChargeShot-csA.vue'
import Melee_B from '../views/Melee-B.vue'
import Boost_C from '../views/Boost-C.vue'
import Jump from '../views/Jump.vue'
import BoostDash from '../views/BoostDash.vue'
import Step from '../views/Step.vue'
import Overheat from '../views/Overheat.vue'
import Switch_D from '../views/Switch-D.vue'
import ButtonCombos from '../views/ButtonCombos.vue'
import SubShot from '../views/SubShot.vue'
import SpecialShot from '../views/SpecialShot.vue'
import SpecialMelee from '../views/SpecialMelee.vue'
import BurstInfo from '../views/BurstInfo.vue'
import FightingBurst from '../views/FightingBurst.vue'
import ShootingBurst from '../views/ShootingBurst.vue'
import ExtendBurst from '../views/ExtendBurst.vue'
import BurstAttack from '../views/BurstAttack.vue'
import CostSystem from '../views/CostSystem.vue'
import Cost1500 from '../views/Cost1500.vue'
import Cost2000 from '../views/Cost2000.vue'
import Cost2500 from '../views/Cost2500.vue'
import Cost3000 from '../views/Cost3000.vue'
import HUD from '../views/HUD.vue'
import WDIDN1 from '../views/WDIDN1.vue'
import TrulyBegins from '../views/TrulyBegins.vue'
import Notation from '../views/Notation.vue'
import BDCancel from '../views/BDCancel.vue'
import BRZunda from '../views/BRZunda.vue'
import BDMeleeCancel from '../views/BDMeleeCancel.vue'
import DirectCancel from '../views/DirectCancel.vue'
import LeverShift from '../views/LeverShift.vue'
import Stepcanceling from '../views/Stepcanceling.vue'
import MAMode from '../views/MAMode.vue'
import AddtlNot from '../views/AddtlNot.vue'
import CommUse from '../views/CommUse.vue'
import Cutting from '../views/Cutting.vue'
import DmgScaling from '../views/DmgScaling.vue'
import DownValue from '../views/DownValue.vue'
import CostOver from '../views/CostOver.vue'
import WDIDN2 from '../views/WDIDN2.vue'
import EveryLittleBit from '../views/EveryLittleBit.vue'
import BarColor from '../views/BarColor.vue'
import BDHop from '../views/BDHop.vue'
import Fuwafuwa from '../views/Fuwafuwa.vue'
import Fuwastep from '../views/Fuwastep.vue'
import JumpCancel from '../views/JumpCancel.vue'
import VernierState from '../views/VernierState.vue'
import OverheatPenalty from '../views/OverheatPenalty.vue'
import FiveShoot from '../views/FiveShoot.vue'
import ProjTrack from '../views/ProjTrack.vue'
import RetColor from '../views/RetColor.vue'
import MuzzCorr from '../views/MuzzCorr.vue'
import WeapSkew from '../views/WeapSkew.vue'
import FireArc from '../views/FireArc.vue'
import CamChange from '../views/CamChange.vue'
import TrackCancel from '../views/TrackCancel.vue'
import RedGreen from '../views/RedGreen.vue'
import SecondImpact from '../views/SecondImpact.vue'
import Multilock from '../views/Multilock.vue'
import SwivelSwerve from '../views/SwivelSwerve.vue'
import WeaponTypes from '../views/WeaponTypes.vue'
import TeamComps from '../views/TeamComps.vue'
import Comp1515 from '../views/Comp1515.vue'
import Comp1520 from '../views/Comp1520.vue'
import Comp1525 from '../views/Comp1525.vue'
import Comp1530 from '../views/Comp1530.vue'
import Comp2020 from '../views/Comp2020.vue'
import Comp2025 from '../views/Comp2025.vue'
import Comp2030 from '../views/Comp2030.vue'
import Comp2525 from '../views/Comp2525.vue'
import Comp2530 from '../views/Comp2530.vue'
import Comp3030 from '../views/Comp3030.vue'
import FrontBack from '../views/FrontBack.vue'
import WDIDN3 from '../views/WDIDN3.vue'
import FineTune from '../views/FineTune.vue'
import DirectInf from '../views/DirectInf.vue'
import MeleeSwitch from '../views/MeleeSwitch.vue'
import FastfallOp from '../views/FastfallOp.vue'
import AssistMain from '../views/AssistMain.vue'
import MeleeCS from '../views/MeleeCS.vue'
import BackturnShot from '../views/BackturnShot.vue'
import AAFastfall from '../views/AAFastfall.vue'
import ReloadFastfall from '../views/ReloadFastfall.vue'
import SBurstShen from '../views/SBurstShen.vue'
import YellowGrab from '../views/YellowGrab.vue'
import BrakeCancel from '../views/BrakeCancel.vue'
import Sliding from '../views/Sliding.vue'
import Pyonkaku from '../views/Pyonkaku.vue'
import PyonBrake from '../views/PyonBrake.vue'
import RetreatPenalty from '../views/RetreatPenalty.vue'
import GreenLockMelee from '../views/GreenLockMelee.vue'
import OverheatStall from '../views/OverheatStall.vue'
import WDIDN4 from '../views/WDIDN4.vue'
import YouShouldTeach from '../views/YouShouldTeach.vue'
import WhyShouldTeach from '../views/WhyShouldTeach.vue'
import HowToTeach from '../views/HowToTeach.vue'
import InfoSynth from '../views/InfoSynth.vue'
import LookAhead from '../views/LookAhead.vue'
import ReadRoom from '../views/ReadRoom.vue'
import AutoResp from '../views/AutoResp.vue'
import AdapResp from '../views/AdapResp.vue'
import NoWaste from '../views/NoWaste.vue'
import NoGuide from '../views/NoGuide.vue'
import SurePath from '../views/SurePath.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    // adds meta tags ie) title, description
    meta: {
      title: 'GundamGuide.com',
      metaTags: [
        {
          property: 'og:description',
          content: 'If you want to learn how to play Mobile Suit Gundam Extreme Versus Maxiboost ON, this guide offers the most structured, research-driven, results-focused method to do it!'
        },
        {
          property: 'twitter:card', 
          content: 'summary'
        },
        {
          property: 'og:type', 
          content: "website"
        },
        { 
         property: 'twitter:site',
         content: '@itsgundamguide'
        },
        {
          property: 'twitter:creator', 
          content: '@itsgundamguide'
        },
        {
          property: 'og:url',
          content:'http://www.gundamguide.com'
        }

      ]
    }
  },
  {
    path: '/dedication',
    name: 'Dedication',
    component: Dedication,
    meta: {
      title: 'GundamGuide.com - Dedication'
    }
  },
  {
    path: '/foreword',
    name: 'Foreword',
    component: Foreword
  },
  {
    path: '/credits',
    name: 'Credits',
    component: Credits
  },
  {
    path: '/TheMostBasicOfBasics',
    name: 'TheMostBasicOfBasics',
    component: TheMostBasicOfBasics
  },
  {
    path: '/DS4Controls',
    name: 'DS4Controls',
    component: DS4Controls
  },
  {
    path: '/StickControls',
    name: 'StickControls',
    component: StickControls
  },
  {
    path: '/numpad',
    name: 'numpad',
    component: Numpad
  },
  {
    path: '/Movement',
    name: 'Movement',
    component: Movement
  },
  {
    path: '/Guard',
    name: 'Guard',
    component: Guard
  },
  {
    path: '/Shot-A',
    name: 'Shot-A',
    component: Shot_A
  },
  {
    path: '/ChargeShot-csA',
    name: 'ChargeShot-csA',
    component: ChargeShot_csA
  },
  {
    path: '/Melee-B',
    name: 'Melee-B',
    component: Melee_B
  },
  {
    path: '/Boost-C',
    name: 'Boost-C',
    component: Boost_C
  },
  {
    path: '/Jump',
    name: 'Jump',
    component: Jump
  },
  {
    path: '/BoostDash',
    name: 'BoostDash',
    component: BoostDash
  },
  {
    path: '/Step',
    name: 'Step',
    component: Step
  },
  {
    path: '/Overheat',
    name: 'Overheat',
    component: Overheat
  },
  {
    path: '/Switch-D',
    name: 'Switch-D',
    component: Switch_D
  },
  {
    path: '/ButtonCombos',
    name: 'ButtonCombos',
    component: ButtonCombos
  },
  {
    path: '/SubShot',
    name: 'SubShot',
    component: SubShot
  },
  {
    path: '/SpecialShot',
    name: 'SpecialShot',
    component: SpecialShot
  },
  {
    path: '/SpecialMelee',
    name: 'SpecialMelee',
    component: SpecialMelee
  },
  {
    path: '/BurstInfo',
    name: 'BurstInfo',
    component: BurstInfo
  },
  {
    path: '/FightingBurst',
    name: 'FightingBurst',
    component: FightingBurst
  },
  {
    path: '/ShootingBurst',
    name: 'ShootingBurst',
    component: ShootingBurst
  },
  {
    path: '/ExtendBurst',
    name: 'ExtendBurst',
    component: ExtendBurst
  },
  {
    path: '/BurstAttack',
    name: 'BurstAttack',
    component: BurstAttack
  },
  {
    path: '/CostSystem',
    name: 'CostSystem',
    component: CostSystem
  },
  {
    path: '/Cost1500',
    name: 'Cost1500',
    component: Cost1500
  },
  {
    path: '/Cost2000',
    name: 'Cost2000',
    component: Cost2000
  },
  {
    path: '/Cost2500',
    name: 'Cost2500',
    component: Cost2500
  },
  {
    path: '/Cost3000',
    name: 'Cost3000',
    component: Cost3000
  },
  {
    path: '/HUD',
    name: 'HUD',
    component: HUD
  },
  {
    path: '/WDIDN1',
    name: 'WDIDN1',
    component: WDIDN1
  },
  {
    path: '/TrulyBegins',
    name: 'TrulyBegins',
    component: TrulyBegins
  },
  {
    path: '/Notation',
    name: 'Notation',
    component: Notation
  },
  {
    path: '/BDCancel',
    name: 'BDCancel',
    component: BDCancel
  },
  {
    path: '/BRZunda',
    name: 'BRZunda',
    component: BRZunda
  },
  {
    path: '/BDMeleeCancel',
    name: 'BDMeleeCancel',
    component: BDMeleeCancel
  },
  {
    path: '/DirectCancel',
    name: 'DirectCancel',
    component: DirectCancel
  },
  {
    path: '/LeverShift',
    name: 'LeverShift',
    component: LeverShift
  },
  {
    path: '/Stepcanceling',
    name: 'Stepcanceling',
    component: Stepcanceling
  },
  {
    path: '/MAMode',
    name: 'MAMode',
    component: MAMode
  },
  {
    path: '/AddtlNot',
    name: 'AddtlNot',
    component: AddtlNot
  },
  {
    path: '/CommUse',
    name: 'CommUse',
    component: CommUse
  },
  {
    path: '/Cutting',
    name: 'Cutting',
    component: Cutting
  },
  {
    path: '/DmgScaling',
    name: 'DmgScaling',
    component: DmgScaling
  },
  {
    path: '/DownValue',
    name: 'DownValue',
    component: DownValue
  },
  {
    path: '/CostOver',
    name: 'CostOver',
    component: CostOver
  },
  {
    path: '/WDIDN2',
    name: 'WDIDN2',
    component: WDIDN2
  },
  {
    path: '/EveryLittleBit',
    name: 'EveryLittleBit',
    component: EveryLittleBit
  },
  {
    path: '/BarColor',
    name: 'BarColor',
    component: BarColor
  },
  {
    path: '/BDHop',
    name: 'BDHop',
    component: BDHop
  },
  {
    path: '/Fuwafuwa',
    name: 'Fuwafuwa',
    component: Fuwafuwa
  },
  {
    path: '/Fuwastep',
    name: 'Fuwastep',
    component: Fuwastep
  },
  {
    path: '/JumpCancel',
    name: 'JumpCancel',
    component: JumpCancel
  },
  {
    path: '/VernierState',
    name: 'VernierState',
    component: VernierState
  },
  {
    path: '/OverheatPenalty',
    name: 'OverheatPenalty',
    component: OverheatPenalty
  },
  {
    path: '/FiveShoot',
    name: 'FiveShoot',
    component: FiveShoot
  },
  {
    path: '/RetColor',
    name: 'RetColor',
    component: RetColor
  },
  {
    path: '/ProjTrack',
    name: 'ProjTrack',
    component: ProjTrack
  },
  {
    path: '/MuzzCorr',
    name: 'MuzzCorr',
    component: MuzzCorr
  },
  {
    path: '/WeapSkew',
    name: 'WeapSkew',
    component: WeapSkew
  },
  {
    path: '/FireArc',
    name: 'FireArc',
    component: FireArc
  },
  {
    path: '/CamChange',
    name: 'CamChange',
    component: CamChange
  },
  {
    path: '/TrackCancel',
    name: 'TrackCancel',
    component: TrackCancel
  },
  {
    path: '/RedGreen',
    name: 'RedGreen',
    component: RedGreen
  },
  {
    path: '/SecondImpact',
    name: 'SecondImpact',
    component: SecondImpact
  },
  {
    path: '/Multilock',
    name: 'Multilock',
    component: Multilock
  },
  {
    path: '/SwivelSwerve',
    name: 'SwivelSwerve',
    component: SwivelSwerve
  },
  {
    path: '/WeaponTypes',
    name: 'WeaponTypes',
    component: WeaponTypes
  },
  {
    path: '/TeamComps',
    name: 'TeamComps',
    component: TeamComps
  },
  {
    path: '/Comp1515',
    name: 'Comp1515',
    component: Comp1515
  },
  {
    path: '/Comp1520',
    name: 'Comp1520',
    component: Comp1520
  },
  {
    path: '/Comp1525',
    name: 'Comp1525',
    component: Comp1525
  },
  {
    path: '/Comp1530',
    name: 'Comp1530',
    component: Comp1530
  },
  {
    path: '/Comp2020',
    name: 'Comp2020',
    component: Comp2020
  },
  {
    path: '/Comp2025',
    name: 'Comp2025',
    component: Comp2025
  },
  {
    path: '/Comp2030',
    name: 'Comp2030',
    component: Comp2030
  },
  {
    path: '/Comp2525',
    name: 'Comp2525',
    component: Comp2525
  },
  {
    path: '/Comp2530',
    name: 'Comp2530',
    component: Comp2530
  },
  {
    path: '/Comp3030',
    name: 'Comp3030',
    component: Comp3030
  },
  {
    path: '/FrontBack',
    name: 'FrontBack',
    component: FrontBack
  },
  {
    path: '/WDIDN3',
    name: 'WDIDN3',
    component: WDIDN3
  },
  {
    path: '/FineTune',
    name: 'FineTune',
    component: FineTune
  },
  {
    path: '/DirectInf',
    name: 'DirectInf',
    component: DirectInf
  },
  {
    path: '/MeleeSwitch',
    name: 'MeleeSwitch',
    component: MeleeSwitch
  },
  {
    path: '/FastfallOp',
    name: 'FastfallOp',
    component: FastfallOp
  },
  {
    path: '/AssistMain',
    name: 'AssistMain',
    component: AssistMain
  },
  {
    path: '/BackturnShot',
    name: 'BackturnShot',
    component: BackturnShot
  },
  {
    path: '/AAFastfall',
    name: 'AAFastfall',
    component: AAFastfall
  },
  {
    path: '/ReloadFastfall',
    name: 'ReloadFastfall',
    component: ReloadFastfall
  },
  {
    path: '/SBurstShen',
    name: 'SBurstShen',
    component: SBurstShen
  },
  {
    path: '/YellowGrab',
    name: 'YellowGrab',
    component: YellowGrab
  },
  {
    path: '/BrakeCancel',
    name: 'BrakeCancel',
    component: BrakeCancel
  },
  {
    path: '/Sliding',
    name: 'Sliding',
    component: Sliding
  },
  {
    path: '/Pyonkaku',
    name: 'Pyonkaku',
    component: Pyonkaku
  },
  {
    path: '/PyonBrake',
    name: 'PyonBrake',
    component: PyonBrake
  },
  {
    path: '/RetreatPenalty',
    name: 'RetreatPenalty',
    component: RetreatPenalty
  },
  {
    path: '/GreenLockMelee',
    name: 'GreenLockMelee',
    component: GreenLockMelee
  },
  {
    path: '/OverheatStall',
    name: 'OverheatStall',
    component: OverheatStall
  },
  {
    path: '/MeleeCS',
    name: 'MeleeCS',
    component: MeleeCS
  },
  {
    path: '/WDIDN4',
    name: 'WDIDN4',
    component: WDIDN4
  },
  {
    path: '/YouShouldTeach',
    name: 'YouShouldTeach',
    component: YouShouldTeach
  },
  {
    path: '/WhyShouldTeach',
    name: 'WhyShouldTeach',
    component: WhyShouldTeach
  },
  {
    path: '/HowToTeach',
    name: 'HowToTeach',
    component: HowToTeach
  },
  {
    path: '/InfoSynth',
    name: 'InfoSynth',
    component: InfoSynth
  },
  {
    path: '/LookAhead',
    name: 'LookAhead',
    component: LookAhead
  },
  {
    path: '/ReadRoom',
    name: 'ReadRoom',
    component: ReadRoom
  },
  {
    path: '/AutoResp',
    name: 'AutoResp',
    component: AutoResp
  },
  {
    path: '/AdapResp',
    name: 'AdapResp',
    component: AdapResp
  },
  {
    path: '/NoWaste',
    name: 'NoWaste',
    component: NoWaste
  },
  {
    path: '/NoGuide',
    name: 'NoGuide',
    component: NoGuide
  },
  {
    path: '/SurePath',
    name: 'SurePath',
    component: SurePath
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.afterEach(() => {
  window.scrollTo(0, 0);


})

router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router
